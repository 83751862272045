// 500.tsx for 500 error Page

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Head from 'next/head';
import { NextPage } from 'next';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '200px',
    width: '260px',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000',
    textAlign: 'center',
  },
  content: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(21, 25, 32, 0.5)',
    marginTop: '10px',
    textAlign: 'center',
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: '35px',
  },
});
const Custom500Page: NextPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>Zaapi</title>
        <meta name="description" content="Zaapi - Create your online selling website in seconds" />
        <link rel="icon" href="/images/favicon.png" />
      </Head>
      <Box className={classes.root}>
        <img className={classes.image} src="/store_not_found.svg" width="140" height="140" />
        <Typography className={classes.title}>{t('Store URL does not exist!')}</Typography>
        <Typography className={classes.content}>
          {t('Looks like the store that you are looking for does not exist. Please enter a valid URL and try again.')}
        </Typography>
      </Box>
    </>
  );
};

export default Custom500Page;
